<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Expendiente
        </p>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <v-card tile>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <p class="subtitle-1">
                    Seleccione el curso para ver el detalle
                  </p>
                  <v-select
                    label="Curso Cursado"
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Año 2020</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Programa #1</v-list-item-title>
                  <v-list-item-subtitle>Curso #1</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title>Información</v-list-item-title>
                  <v-list-item-subtitle>
                    Idioma: Mandarín
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Costo: $50
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Nota</v-list-item-title>
                  <v-list-item-subtitle>-10</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Proceedings",
};
</script>

<style></style>
